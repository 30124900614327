<template>
<div>
  <v-card max-width="370" class="mx-auto mt-5" flat>
    <v-card-title>
      {{name}}
    </v-card-title>

    <v-card-text>
      User Id: {{uid}}<br>
      Admin: {{claims.admin}} <br>
      System Admin: {{claims.sysAdmin}} <br>
      Teacher: {{claims.teacher}} <br>
      Student: {{claims.student}} <br>
      Institute Id: {{claims.instituteId}} <br>
      Institute Name: {{settings.name}}
    </v-card-text>
  </v-card>
  
</div>
</template>

<script>
import {auth, institutesCollection, teachersCollection} from '@/firebase';

export default {

  data() {
    return {
      uid: '',
      claims: {},
      settings: {},
      name: '',
    }
  },


  async created() {
    this.uid = await auth.currentUser.uid;
    //get results of custom claims
    this.claims = (await auth.currentUser.getIdTokenResult()).claims;
    console.log('claims:', this.claims);

    //get the name of currently logged in user
    const userProfileDoc = await teachersCollection.doc(this.uid).get();
    if(userProfileDoc.exists) {
      let userData = userProfileDoc.data();
      this.name = userData.firstname + ' ' + userData.lastname;
    } else {
      console.log('User-Document does not exist.')
    }

    this.getSettings();
  },

  methods: {
    async getSettings(){
      //get institute settings
      const doc = await institutesCollection.doc(this.claims.instituteId).get();
      if (doc.exists) {
        this.settings = doc.data();
        console.log('Institute Settings:', doc.data())
      } else {
        throw "Could not find this doument in firestore";
      }
    }
  }
}
</script>

<style>

</style>